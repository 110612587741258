import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { ViewActivateComponent } from './components/view-activate/view-activate.component';
// import { ViewDebugComponent } from './components/view-debug/view-debug.component';
// import { ViewLoginComponent } from './components/view-login/view-login.component';
// import { ViewPreRegisterComponent } from './components/view-pre-register/view-pre-register.component';
// import { ViewRegisterComponent } from './components/view-register/view-register.component';
// import { ViewUserManagerComponent } from './components/view-user-manager/view-user-manager.component';
import { AuthGuard } from './services/auth.guard';
import { routes } from './routes';


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

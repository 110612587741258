import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar, MatCalendarCellCssClasses, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { IEmployeeWorksheet } from 'src/app/model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService2 } from 'src/app/services/auth.service';
import { ViewWorkSchedulePopupComponent, ShiftInfo } from './view-work-schedule-popup/view-work-schedule-popup.component';
import { AsyncPipe, DatePipe, formatDate } from '@angular/common';
import { merge, Observable, tap } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

const compareDate = (a: Date, b: Date): boolean => {
  return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
};

@Component({
    selector: 'app-view-work-schedule',
    templateUrl: './view-work-schedule.component.html',
    styleUrls: ['./view-work-schedule.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [MatDatepickerModule, MatCardModule, DatePipe, MatButtonModule, MatIconModule, MatDividerModule]
})
export class ViewWorkScheduleComponent {
  calendar: MatCalendar<Date> | null = null;

  @ViewChild('calendar') set setCalendar(calendar: MatCalendar<Date>) {
    this.calendar = calendar;
    this.calendar.activeDate = new Date(1900, 1, 1);  // to enable update on load worksheets
    this.subscribeCalChanges();
    this.setActiveDate(this.activeDate);
  }

  worksheets: IEmployeeWorksheet[] | null = null;
  activeDate: Date = new Date();

  dateClass = (d: Date): MatCalendarCellCssClasses => {
    if (!this.worksheets) {
      return "";
    }
    let fact_total = 0;
    let plan_total = 0;
    let day_plan_total = 0;
    let night_plan_total = 0;
    for (const company of this.worksheets) {
      for (const record of company.shifts) {
        if (compareDate(d, new Date(record.date))) {
          day_plan_total += record.plan_day_hours;
          night_plan_total += record.plan_night_hours;
          fact_total += record.fact_day_hours;
          plan_total += record.plan_day_hours;
          fact_total += record.fact_night_hours;
          plan_total += record.plan_night_hours;
        }
      }
    }
    if (fact_total === 0 && plan_total === 0) {
      return '';
    } else if (fact_total >= plan_total) {
      return 'highlight-date';
    } else {
      if (night_plan_total >= day_plan_total)
        return 'highlight-date-planned-night';
      else
        return 'highlight-date-planned-day';
    }
    // const result = aa <= bb ? 'highlight-date-planned' : 'highlight-date';
    //return result;
  };

  constructor(
    private apiService: ApiService,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private dateAdapter: DateAdapter<Date>,
    public auth: AuthService2,
    private dialog: MatDialog
  ) {
    dateAdapter.setLocale('ru');
    dateAdapter.getFirstDayOfWeek = () => 1;
    this.activeDate = this.dateAdapter.today();
  }

  subscribeCalChanges(): void {
    if (!this.calendar) {
      return;
    }
    this.calendar.selectedChange.subscribe((d: Date) => {
      console.log('calendar.selectedChange');
      this.onDayInfo(d);
    });
  }

  getFormatedActiveDate(): string {
    return this.dateAdapter.format(this.activeDate, this.dateFormats.display.dateA11yLabel);
  }

  test(event: Event): void {
    console.log(event);
  }

  setActiveDate(date: Date): void {
    this.activeDate = date;
    const period = date.getFullYear() + "_" + ("0" + (date.getMonth() + 1)).slice(-2);
    this.apiService.getEmployeeWorksheets(period).pipe(
      tap((worksheets) => this.worksheets = worksheets)
    ).subscribe(() => {
      if (this.calendar) {
        this.calendar.activeDate = date;
      }
    });
  }

  prev_period(): void {
    const dy = this.activeDate.getMonth() == 0 ? -1 : 0;
    const m = dy == 0 ? this.activeDate.getMonth() - 1 : 11;
    const date = new Date(this.activeDate.getFullYear() + dy, m, 1);
    // console.log('prev_period', date);
    this.setActiveDate(date);
  }

  next_period(): void {
    const dy = this.activeDate.getMonth() == 11 ? 1 : 0;
    const m = dy ? 0 : this.activeDate.getMonth() + 1;
    const date = new Date(this.activeDate.getFullYear() + dy, m, 1);
    // console.log('next_period', date);
    this.setActiveDate(date);
  }

  onDayInfo(date: Date): void {
    if (!this.worksheets) {
      return;
    }

    let hours = 0;
    const dayShifts: ShiftInfo[] = [];

    for (const wsheet of this.worksheets) {
      for (const record of wsheet.shifts) {
        if (!compareDate(date, new Date(record.date))) {
          continue;
        }

        const period = {
          company: wsheet.workplace,
          position: wsheet.position,
          fact_day_hours: record.fact_day_hours,
          fact_night_hours: record.fact_night_hours,
          plan_day_hours: record.plan_day_hours,
          plan_night_hours: record.plan_night_hours,
        };

        hours += period.fact_day_hours;
        hours += period.fact_night_hours;
        hours += period.plan_day_hours;
        hours += period.plan_night_hours;

        dayShifts.push(period);
      }
    }

    if (!hours) {
      return;
    }

    this.dialog.open(ViewWorkSchedulePopupComponent, {
      width: '400px',
      maxWidth: '90vw',
      disableClose: false,
      data: {
        shifts: dayShifts,
        date,
      },
    });
  }
}

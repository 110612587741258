/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { AccessToken } from '../models/AccessToken';
import type { LoginData } from '../models/LoginData';
import type { LoginPasswordData } from '../models/LoginPasswordData';
import type { UserProfile } from '../models/UserProfile';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Auth Login
     * @param requestBody
     * @returns AccessToken Successful Response
     * @throws ApiError
     */
    public authLoginApiAuthLoginPost(
        requestBody: LoginPasswordData,
    ): Observable<AccessToken> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public checkUserApiAuthCheckUserPost(
        requestBody: LoginData,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/check_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Profile
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public getProfileApiAuthProfileGet(): Observable<UserProfile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/profile',
        });
    }
    /**
     * Request Password Reset Code
     * @param login
     * @returns any Successful Response
     * @throws ApiError
     */
    public requestPasswordResetCodeApiAuthRequestPasswordResetCodePost(
        login: string,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/request_password_reset_code',
            query: {
                'login': login,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reset Password
     * @param login
     * @param resetCode
     * @returns any Successful Response
     * @throws ApiError
     */
    public resetPasswordApiAuthResetPasswordPost(
        login: string,
        resetCode: string,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/reset_password',
            query: {
                'login': login,
                'reset_code': resetCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

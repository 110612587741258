import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { IWikiStructure } from '../../model';
import { ApiService } from '../../services/api.service';
import { ViewWikiPopupComponent } from '../view-wiki-popup/view-wiki-popup.component';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { Subscriber, Subscription, finalize } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-view-wiki',
    templateUrl: './view-wiki.component.html',
    styleUrls: ['./view-wiki.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTreeModule,
        RouterLink,
        MatProgressBarModule,
        MatCardModule
    ]
})
export class ViewWikiComponent implements OnInit, OnDestroy {
  treeControl = new NestedTreeControl<IWikiStructure>((node) => node.sub);
  dataSource: MatTreeNestedDataSource<IWikiStructure> | null = null;
  searchForm = this.fb.group({
    query: ['', Validators.required],
  });

  get query(): FormControl {
    return this.searchForm.get('query') as FormControl;
  }

  searchInProgress = false;
  noDocumentsFound = false;
  searchPagesSubscriptions: Subscription[] = [];

  constructor(private apiService: ApiService, private dialog: MatDialog, private fb: FormBuilder) {}

  hasChild = (_: number, node: IWikiStructure): boolean => !!node.sub && node.sub.length > 0;

  ngOnInit(): void {
    this.dataSource = null;
    this.apiService.getWikiAvailablePages().subscribe((pages) => {
      this.dataSource = new MatTreeNestedDataSource<IWikiStructure>()
      // console.log('wiki structure: ', pages);
      if (pages && pages.length > 0) {
        this.dataSource.data = pages;
        this.treeControl.dataNodes = pages;
        this.treeControl.expandAll();
      } else {
        this.dataSource.data = [];
      }
    });
  }

  ngOnDestroy(): void {
    this.dropCurrentSearches();
  }

  dropCurrentSearches(): void {
    this.searchPagesSubscriptions.forEach((subscription) => subscription.unsubscribe())
    this.searchPagesSubscriptions = [];
  }

  searchWikiPage(): void {
    if (this.searchInProgress) {
      // this is weird, search buttnon should be blocked, but Enter hit is working
      return;
    }
    this.searchInProgress = true;
    this.noDocumentsFound = false;
    this.dropCurrentSearches();
    const searchPagesSubscription = this.apiService.searchWikiPages(this.query?.value).pipe(
      // takeUntilDestroyed(),
      finalize(() => this.searchInProgress = false),
    ).subscribe((p) => {
      if (p.length) {
        this.dialog.open(ViewWikiPopupComponent, {
          minWidth: '400px',
          maxWidth: '90vw',
          disableClose: false,
          data: {
            pages: p,
          },
        });
      } else {
        this.noDocumentsFound = true;
      }
    });
    this.searchPagesSubscriptions.push(searchPagesSubscription)
  }
}

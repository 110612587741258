import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService2 } from 'src/app/services/auth.service';
import { IEmployeeWorkplacePositionSalary, IPeriod, IWorkplacePosition } from '../../model';
import { ApiService } from '../../services/api.service';
import { ViewSalaryPopupComponent } from '../view-salary-popup/view-salary-popup.component';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { DecimalPipe, AsyncPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatListModule } from '@angular/material/list';
import { Observable, map, shareReplay } from 'rxjs';

interface ISalaryPeriod extends IPeriod {
  panelOpened: boolean
};

@Component({
    selector: 'app-view-salary',
    templateUrl: './view-salary.component.html',
    styleUrls: ['./view-salary.component.scss'],
    imports: [
        MatListModule,
        FlexModule,
        ExtendedModule,
        MatExpansionModule,
        MatCardModule,
        RouterLink,
        DecimalPipe,
        AsyncPipe
    ]
})
export class ViewSalaryComponent {
  periods$: Observable<ISalaryPeriod[]>;

  constructor(private apiService: ApiService, public auth: AuthService2, private dialog: MatDialog) {
    this.periods$ = this.apiService.getEmployeeSalary3().pipe(
      map((salary) => this.convertSalaryToPeriods(salary)),
      shareReplay(1)
    );
  }

  private convertSalaryToPeriods(salary: IEmployeeWorkplacePositionSalary[]): ISalaryPeriod[] {
    const periods: ISalaryPeriod[] = [];

    for (const s of salary) {
      for (const p of s.periods) {
        if (periods.find((pp) => pp.name === p.period)) {
          continue;
        }

        const period: ISalaryPeriod = {
          name: p.period,
          total_onhand: 0,
          workplacePositions: [],
          comments: [],
          panelOpened: false
        };
        periods.push(period);

        for (const s2 of salary) {
          for (const p2 of s2.periods) {
            if (p2.period === period.name) {
              const aaa: IWorkplacePosition = {
                workplace: s2.workplace,
                position: s2.position,
                total_onhand: p2.total_onhand,
                total_bounty: p2.total_bounty,
                bounty_details: p2.bounty_details,
                payments: p2.payments,
                company: "",  // todo
                period: p2.period
              };
              period.total_onhand += p2.total_onhand;
              period.workplacePositions.push(aaa);
              Array.prototype.push.apply(period.comments, p2.comments);

              // unique
              period.comments = period.comments.filter((it, i, ar) => ar.indexOf(it) === i);
            }
          }
        }
      }

      periods.sort((x, y) => -x.name.localeCompare(y.name));
    }

    // console.log('Converted salary: ', periods);
    return periods;
  }

  onSalaryInfo(salaryPeriod: IWorkplacePosition): void {
    const title = 'Детализация';
    this.dialog.open(ViewSalaryPopupComponent, {
      width: '400px',
      maxWidth: '90vw',
      disableClose: false,
      data: {
        title,
        company: salaryPeriod.company,
        position: salaryPeriod.position,
        salaryPeriod,
      },
    });
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { CustomWindow } from '../model';
import { ApiService } from './api.service';
import { AuthService, UserProfile } from '../modules/api';
import { Observable, switchMap, tap } from 'rxjs';

// declare let window: CustomWindow;

@Injectable({
  providedIn: 'root',
})
export class AuthService2 {
  returnUrl = '';
  profile: UserProfile | null = null;

  constructor(public router: Router, private api: ApiService, private authAPI: AuthService) {}

  public isAuthenticated(): boolean {
    const val = localStorage.getItem('access_token');
    return val != null && val.length > 0;
  }

  public hasProfile(): boolean {
    return null !== this.profile;
  }

  public getAccessToken(): string {
    const stored = localStorage.getItem('access_token')
    return stored ? stored : "";
  }

  public login(returnUrl: string): boolean {
    // true if immediate login
    if (this.hasProfile()) {
      console.log('YandexMetrika', this.profile);
      // inform yandex metrika
      // window.ym(61430950, 'userParams', {
      //   username: this.profile.personaldata.individual_name,
      //   login: this.profile.login,
      //   UserID: this.profile.login,
      // });
      return true;
    }
    this.returnUrl = returnUrl;
    this.router.navigate(['login']);
    return false;
  }

  public getProfile(): UserProfile | null {
    // if (!this.profile)
      //  throw new Error('Profile is not loaded');
    return this.profile;
  }

  // public isActivated(): boolean {
  //   return null != this.profile /*&& !this.profile.activation*/;
  // }

  public logout(): void {
    localStorage.removeItem('access_token');
    this.profile = null;
    this.router.navigate(['login']);
  }

  public getUserName(): string {
    const name = localStorage.getItem('user');
    return name ? name : "";
  }

  public authenticate(username: string, password: string): Observable <UserProfile> {
    return this.api.login(username, password).pipe(
      tap((token)=>{
        if (token) {
          localStorage.setItem('access_token', token.access_token);
        } else {
         localStorage.removeItem('access_token');
        }
      }),
      switchMap(() => this.loadProfile())
    );
  }

  public register(username: string, password: string): Observable<UserProfile> {
    return this.api.register(username, password).pipe(
      tap((token)=>{
        if (token) {
          localStorage.setItem('access_token', token.access_token);
          localStorage.setItem('username', username);
        } else {
          localStorage.removeItem('access_token');
        }
      }),
      switchMap(() => this.loadProfile())
    );
  }

  public loadProfile(): Observable<UserProfile> {
    return this.authAPI.getProfileApiAuthProfileGet().pipe(
      tap((profile)=>{
        this.profile = profile;
        console.log("Profile loaded for role: ", this.profile.role);
      }),
    );
  }

  // public forgotPassword(username: string): Observable<any> {
  //   return this.api.forgotPassword(username).pipe(
  //     // tap(()=>{
  //     //   localStorage.setItem('username', username);
  //     // })
  //   );
  // }

  // public resetPassword(password: string, code: string): Observable<any> {
  //   var username = localStorage.getItem('username');
  //   username = username ? username : "";
  //   return this.api.resetPassword(username, password, code);
  // }
}

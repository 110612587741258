import { Component, OnInit } from '@angular/core';
import { AuthService2 } from 'src/app/services/auth.service';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { Birthday, EmployeeService } from 'src/app/modules/api';

@Component({
    selector: 'app-view-birthdays',
    templateUrl: './view-birthdays.component.html',
    styleUrls: ['./view-birthdays.component.scss'],
    imports: [AsyncPipe, MatListModule]
})
export class ViewBirthdaysComponent implements OnInit {
  birthdays$: Observable<Birthday[]>;

  constructor(private employeeService: EmployeeService, public auth: AuthService2) {
    this.birthdays$ = this.employeeService.getBirthdaysApiEmployeeBirthdaysGet();
  }

  ngOnInit(): void {
  }

  convertDate(date: string): string {
    // let monthName = moment(3, 'M').format('MMM'); // returns Mar
    return "";
  }
}

<mat-dialog-content>
    <div class="err-header">
        <mat-icon>error</mat-icon>
        <h2>Ошибка!</h2>    
    </div>
    <hr>
    {{message}}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close routerLink="/">
        Главная
    </button>
    <button mat-raised-button mat-dialog-close routerLink="/gri">
        Login
    </button>
    <button mat-raised-button color="primary" mat-dialog-close>
        OK
    </button>

</mat-dialog-actions>
import { Component } from '@angular/core';
import { AuthService2 } from '../../services/auth.service';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { EmployeeService, Vacation } from 'src/app/modules/api';

@Component({
    selector: 'app-view-graphic-vacation',
    templateUrl: './view-graphic-vacation.component.html',
    styleUrls: ['./view-graphic-vacation.component.scss'],
    imports: [AsyncPipe, MatListModule]
})
export class ViewGraphicVacationComponent {
  vacations$: Observable<Vacation[]>;
  constructor(private employeeService: EmployeeService, public auth: AuthService2) {
    this.vacations$ = this.employeeService.getVacationScheduleApiEmployeeVacationScheduleGet();
  }
}

// :host {   ViewEncapsulation.None blocks it
app-view-work-schedule {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.cal-container {
  width: 100%;
  max-width: 400px;
}

.highlight-date .mat-calendar-body-cell-content {
  background: #6bb46b;
}

.highlight-date-planned-day .mat-calendar-body-cell-content {
  background: #f8ae00;
}

.highlight-date-planned-night .mat-calendar-body-cell-content {
  background: #c473ff;
}

.mat-calendar-header {
  display: none;
}

.mat-calendar {
  padding-top: 30px;
  min-height: 400px;
}

.buttons { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.buttons-caption { 
  margin-right: 15px;
  margin-left: 15px;
}

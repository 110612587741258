import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
// import { AuthService2 } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { PhoneMaskDirective } from '../../directives/phone-mask.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { AuthLayoutComponent } from '../auth-layout/auth-layout.component';
// import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/modules/api';
import { catchError, EMPTY } from 'rxjs';

@Component({
    selector: 'app-view-reset-password',
    templateUrl: './view-reset-password.component.html',
    styleUrls: ['./view-reset-password.component.scss'],
    imports: [
        AuthLayoutComponent,
        FormsModule,
        FlexModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        PhoneMaskDirective,
        MatButtonModule,
        MatIconModule,
        RouterLink
    ]
})
export class ViewResetPasswordComponent {
  code_sent = false;
  passwordVisibility = false;

  public form = this.fb.group({
    phoneNumber: ['', [Validators.required, Validators.minLength(13)]],
    code: ['', [Validators.required, Validators.minLength(4)]],
  });

  constructor(
    //private auth: AuthService2, 
    private authApiService: AuthService, 
    private fb: FormBuilder, public router: Router) {}

  get phoneNumber(): FormControl {
    return this.form.get('phoneNumber') as FormControl;
  }
  get code(): FormControl {
    return this.form.get('code') as FormControl;
  }

  requestResetPasswordCode(): void {
    this.authApiService.requestPasswordResetCodeApiAuthRequestPasswordResetCodePost(this.phoneNumber.value).subscribe({
      next: () => {
        this.code_sent = true;
        this.phoneNumber.disable();
      },
      error: (e) => {
        this.form.enable();
        // todo: new openapi not support details
        if (e.error === 'Error.UserNotFound') {
          this.phoneNumber?.setErrors({ userNotFound: true });
        } else if (e.error === 'Error.UserNotActive') {
          this.phoneNumber?.setErrors({ userNotActive: true });
        } else  {
          this.phoneNumber?.setErrors({ generalError: true });
        }
      }
    });
  }

  resetPassword(): void {
    // this.form.disable();
    this.authApiService.resetPasswordApiAuthResetPasswordPost(this.phoneNumber.value, this.code.value)
    .pipe(
      catchError((e) => {
        // if (e.error === 'Error.WrongSMSCode') {  # open api not support details
        this.code?.setErrors({
          resetError: true,
        });
        // }    
        return EMPTY; // stop error propagation
      })
    )
    .subscribe(() => {
          this.router.navigate(['/login']);
      });
  }
}

import { Component, ViewEncapsulation } from '@angular/core';
// import { IMenuItem } from 'src/app/model';
import { AuthService2 } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';

import { MatListModule } from '@angular/material/list';
import { routes } from 'src/app/routes';


export interface IMenuItem {
  url: string;
  name: string;
  icon: string;
  roles: string[];
}


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [
        MatListModule,
        RouterLinkActive,
        RouterLink,
        MatIconModule
    ]
})
export class SidebarComponent {
  menuItems: IMenuItem[] = [];

  constructor(private authService: AuthService2) {
    // this.menuItems.length = 0;
    for (const item of routes) {
      if (!item.data.icon)
        continue;
      const menuItem = {
        url: "/" + item.path,
        name: item.data.name,
        icon: item.data.icon,
        roles: item.data.roles
      }
      this.menuItems.push(menuItem);
    }
  }

  get items(): IMenuItem[] {
    const profile = this.authService.getProfile();
    const checkRoles = (menuItem: IMenuItem) => menuItem.roles.length == 0 || menuItem.roles.includes(profile ? profile.role : "");
    return this.menuItems.filter(checkRoles);
  }
}

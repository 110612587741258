/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Body_send_ndfl_email_api_service_send_ndfl_email_post } from '../models/Body_send_ndfl_email_api_service_send_ndfl_email_post';
import type { WikiPage } from '../models/WikiPage';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class MiscService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Wiki Page
     * @param id
     * @returns WikiPage Successful Response
     * @throws ApiError
     */
    public getWikiPageApiServiceGetWikiPageGet(
        id: string,
    ): Observable<WikiPage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/service/get_wiki_page',
            query: {
                '_id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Wiki Page
     * @param q
     * @returns WikiPage Successful Response
     * @throws ApiError
     */
    public searchWikiPageApiServiceSearchWikiPageGet(
        q: string,
    ): Observable<Array<WikiPage>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/service/search_wiki_page',
            query: {
                'q': q,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Wiki Available Pages
     * @returns any Successful Response
     * @throws ApiError
     */
    public getWikiAvailablePagesApiServiceGetWikiAvailablePagesGet(): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/service/get_wiki_available_pages',
        });
    }
    /**
     * Send Ndfl Email
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendNdflEmailApiServiceSendNdflEmailPost(
        requestBody: Body_send_ndfl_email_api_service_send_ndfl_email_post,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/service/send_ndfl_email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Static
     * @param path
     * @returns any Successful Response
     * @throws ApiError
     */
    public getStaticApiServiceStaticPathGet(
        path: string,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/service/static/{path}',
            path: {
                'path': path,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Magazines Array Files
     * @returns any Successful Response
     * @throws ApiError
     */
    public getMagazinesArrayFilesApiServiceMagazinesArrayGet(): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/service/magazines/array',
        });
    }
    /**
     * Heartbeat
     * @returns any Successful Response
     * @throws ApiError
     */
    public heartbeatApiServiceHeartbeatPost(): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/service/heartbeat',
        });
    }
    /**
     * Log Error
     * @param errorMessage
     * @param stack
     * @returns any Successful Response
     * @throws ApiError
     */
    public logErrorApiServiceLogErrorPost(
        errorMessage: string,
        stack: string,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/service/log_error',
            query: {
                'error_message': errorMessage,
                'stack': stack,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Test
     * @returns any Successful Response
     * @throws ApiError
     */
    public testTestGet(): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/test',
        });
    }
    /**
     * Sms Code Link
     * @param code
     * @param phone
     * @returns any Successful Response
     * @throws ApiError
     */
    public smsCodeLinkSmsCodeLinkGet(
        code: string,
        phone: string,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sms_code_link',
            query: {
                'code': code,
                'phone': phone,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}

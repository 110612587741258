import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  IBirthdays,
  IClaim,
  IDebugUser,
  // IEmployeePeriodSalary,
  IEmployeeWorkplacePositionSalary,
  IEmployeeWorksheet,
  IHotelGraphs,
  IHotelShiftInfo,
  INotebook,
  IPeriodsMagazine,
  IProfile,
  IProfileList,
  // ISalaryRecord,
  IToken,
  IVacation,
  IWikiPage,
  IWikiStructure,
} from '../model';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public API_URL = "";

  // getEmployeeSalary3Cache$: Observable<IEmployeeWorkplacePositionSalary[]> | null = null;
  getWikiAvailablePages$: Observable<IWikiStructure[]> | null = null;
  getWikiPageCache$: Record<string, Observable<IWikiPage>> = {};

  constructor(private http: HttpClient, private sanitize: DomSanitizer) {
    if (location.origin.includes("localhost:4200") || location.origin.includes("127.0.0.1:4200")) {
      this.API_URL = environment.localApiURL;
    } else {
      this.API_URL = location.origin;
    }
    console.log("API url:", this.API_URL);
  }

  public heartbeat(): Observable<void> {
    return this.http.post<void>(this.API_URL + '/api/service/heartbeat', {}, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /////////////////////////////////////////////////////
  // login

  public register(login: string, password: string): Observable<IToken> {
    return this.http.post<IToken>(this.API_URL + '/api/auth/register', {
      login,
      password,
    });
  }

  public login(login: string, password: string): Observable<IToken> {
    return this.http.post<IToken>(this.API_URL + '/api/auth/login', {
      login,
      password,
    });
  }

  public check_user(login: string): Observable<string> {
    return this.http.post<string>(this.API_URL + '/api/auth/check_user', {
      login
    });
  }

  // public activate(code: string): Observable<void> {
  //   return this.http.post<void>(this.API_URL + '/api/auth/activate', { code });
  // }

  // public resendCode(): Observable<void> {
  //   return this.http.post<void>(this.API_URL + '/api/auth/resend_code', {});
  // }

  public getProfile(): Observable<IProfile> {
    return this.http.get<IProfile>(this.API_URL + '/api/auth/profile').pipe(
      tap((profile) => {
        profile.role = profile.role_lk;
      })
    );
  }

  // public requestPasswordResetCode(login: string): Observable<void> {
  //   return this.http.post<void>(this.API_URL + '/api/auth/request_password_reset_code', {
  //     login,
  //   });
  // }

  // public resetPassword(login: string, code: string): Observable<void> {
  //   return this.http.post<void>(this.API_URL + '/api/auth/reset_password', {
  //     login,
  //     code,
  //   });
  // }

  /////////////////////////////////////////////////////
  // debug

  public debugSetLoginOverride(login: string): Observable<void> {
    return this.http.post<void>(this.API_URL + '/api/auth/debug_set_login_override', { login });
  }

  public debugGetAvailableUsers(): Observable<IDebugUser[]> {
    return this.http.get<IDebugUser[]>(this.API_URL + '/api/auth/debug_get_available_users');
  }

  public debugUnsetLoginOverride(): Observable<void> {
    return this.http.delete<void>(this.API_URL + '/api/auth/debug_unset_login_override');
  }

  /////////////////////////////////////////////////////
  // users

  public getUsers(): Observable<IProfileList> {
    return this.http.get<IProfileList>(`${this.API_URL}/api/admin/users`);
  }

  public setUserPosition(login: string, matrix_position: string[]): Observable<IProfile> {
    return this.http.patch<IProfile>(`${this.API_URL}/api/admin/user_position`, { login, matrix_position });
  }

  /////////////////////////////////////////////////////
  // employees

  public getEmployeePeriods(): Observable<string[]> {
    return this.http.get<string[]>(this.API_URL + '/api/employee/payment_periods');
  }

  public getEmployeePositions(): Observable<string[]> {
    return this.http.get<string[]>(this.API_URL + '/api/employee/positions');
  }

  public getEmployeeCompanies(): Observable<string[]> {
    return this.http.get<string[]>(this.API_URL + '/api/employee/companies');
  }

  // public getEmployeePositionCompanies(): Observable<IPositionCompany[]> {
  //   return this.http.get<IPositionCompany[]>(this.API_URL + '/api/employee/positions_and_companies');
  // }

  public getEmployeeBountyNotebookForPeriod(period: string, position: string, workplace: string): Observable<SafeHtml> {
    return this.http
      .get(this.API_URL + '/api/employee/bounty_notebook', {
        params: {
          period,
          position,
          workplace,
        },
        responseType: 'text',
      })
      .pipe(
        map((html) => {
          return this.sanitize.bypassSecurityTrustHtml(html);
        })
      );
  }

  // public getEmployeeSalaryForPeriod(period: string, position: string, company: string): Observable<ISalaryRecord[]> {
  //   return this.http.get<ISalaryRecord[]>(this.API_URL + '/api/employee/salary', {
  //     params: { period, position, company },
  //   });
  // }

  // public getEmployeeSalary(position: string, company: string): Observable<IEmployeePeriodSalary[]> {
  //   return this.http.get<IEmployeePeriodSalary[]>(this.API_URL + '/api/employee/salary2', {
  //     params: { position, company },
  //   });
  // }

  public getEmployeeSalary3(): Observable<IEmployeeWorkplacePositionSalary[]> {
    return this.http.get<IEmployeeWorkplacePositionSalary[]>(this.API_URL + '/api/employee/salary3')
  }

  public getEmployeeWorksheets(period: string): Observable<IEmployeeWorksheet[]> {
    return this.http.get<IEmployeeWorksheet[]>(this.API_URL + '/api/employee/workshifts', {
      params: { period },
    });
  }

  public getEmployeeHotelShifts(): Observable<IHotelShiftInfo> {
    return this.http.get<IHotelShiftInfo>(this.API_URL + '/api/employee/hotel_shifts');
  }

  public getEmployeeGriGraphs(period: string): Observable<IHotelGraphs[]> {
    return this.http.get<IHotelGraphs[]>(this.API_URL + '/api/employee/gri', {
      params: { period },
    });
  }

  /////////////////////////////////////////////////////
  // wiki

  public getWikiPage(_id: string): Observable<IWikiPage> {
    if (!this.getWikiPageCache$[_id])
      this.getWikiPageCache$[_id] = this.http
        .get<IWikiPage>(this.API_URL + '/api/service/get_wiki_page', {
          params: { _id },
        })
        .pipe(
          map((page) => {
            page.sanitized = this.sanitize.bypassSecurityTrustHtml(page.html);
            return page;
          }),
          shareReplay(1)
        );
    return this.getWikiPageCache$[_id];
  }

  public getWikiAvailablePages(): Observable<IWikiStructure[]> {
    if (!this.getWikiAvailablePages$)
      this.getWikiAvailablePages$ = this.http
        .get<IWikiStructure[]>(this.API_URL + '/api/service/get_wiki_available_pages')
        .pipe(shareReplay(1));
    return this.getWikiAvailablePages$;
  }

  public searchWikiPages(searchQuery: string): Observable<IWikiPage[]> {
    return this.http.get<IWikiPage[]>(this.API_URL + '/api/service/search_wiki_page', {
      params: { q: searchQuery },
    });
  }

  // services
  public sendFeedbackEmail(message: string, attachment: unknown): Observable<void> {
    return this.http.post<void>(this.API_URL + '/api/service/send_feedback_email', {
      message,
      attachment,
    });
  }

  public sendNDFLEmail(workplace: string, period: string): Observable<void> {
    return this.http.post<void>(this.API_URL + '/api/service/send_ndfl_email', {
      workplace,
      period,
    });
  }

  public sendPreRegisterEmail(phone: string, full_name: string, email: string, workplace: string): Observable<void> {
    return this.http.post<void>(this.API_URL + '/api/service/send_pre_register_email', {
      phone,
      full_name,
      email,
      workplace,
    });
  }

  // notebooks
  public getNotebooks(): Observable<INotebook[]> {
    return this.http.get<INotebook[]>(this.API_URL + '/api/notebooks').pipe(
      map((notebooks) => {
        return notebooks.map((n) => {
          this.getRenderedNotebook(n._id).subscribe((html) => (n.rendered = html));
          return n;
        });
      })
    );
  }

  public getRenderedNotebook(id: string): Observable<SafeHtml> {
    return this.http.get(this.API_URL + '/api/notebooks/' + id, { responseType: 'text' }).pipe(
      map((html) => {
        return this.sanitize.bypassSecurityTrustHtml(html);
      })
    );
  }
  public getArrayFiles(): Observable<IPeriodsMagazine[]> {
    return this.http.get<IPeriodsMagazine[]>(this.API_URL + '/api/service/magazines/array');
  }
  // files blobs
  // public getFile(path: string): Observable<ArrayBuffer> {
  //   return this.http.get(this.API_URL + '/api/service/static/' + path, { responseType: 'arraybuffer' });
  // }
  // birthdays
  public getAllBirthdays(): Observable<IBirthdays[]> {
    return this.http.get<IBirthdays[]>(this.API_URL + '/api/employee/birthdays');
  }
  // vacation
  public getPersonalVacation(): Observable<IVacation[]> {
    return this.http.get<IVacation[]>(this.API_URL + '/api/employee/vacation_schedule');
  }
  //claim to service
  public getClaimService(): Observable<IClaim[]> {
    return this.http.get<IClaim[]>(this.API_URL + '/api/employee/claim_service');
  }
}

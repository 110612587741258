<div class="content" >
    @if (personal_data) {
    <mat-card>
    <div matSubheader>{{ personal_data?.individual_name }}</div>
    <mat-card-content>
      <mat-list>
        <mat-list-item>
          <div matLine>Индивидуальный номер: {{ personal_data?.individual_id }}</div>
        </mat-list-item>
        @if (personal_data.birthday) {
        <mat-list-item>
          <div matLine>Дата рождения: {{ personal_data?.birthday | date : 'dd.MM.yyyy' }}</div>
        </mat-list-item>
        }
        <mat-list-item>
          <div matLine>Edelweiss ID: {{ personal_data?.edel_id }}</div>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Медицинская книжка: {{ personal_data?.medical_book }}</div>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Время обновления: {{ personal_data?.timestamp | date: 'HH:mm dd/MM/yyyy '}}</div>
        </mat-list-item>
        <mat-list-item>
          <div matLine>Wiki доступ: {{ profile?.matrix_position }}</div>
        </mat-list-item>
      </mat-list>
    </mat-card-content>

    <mat-card-actions align="end">
      <button mat-raised-button color="primary" (click)="auth.logout()">Выйти</button>
    </mat-card-actions>
  </mat-card>
  }

  <mat-accordion>
    @for (e of personal_data?.employments; track e; let i = $index) {
      <!-- (opened)="period.panelOpened = true"
      (closed)="period.panelOpened = false" -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <!-- <div matSubheader>Трудоустройство {{ i + 1 }}</div> -->
          <div matSubheader>Место работы: {{ e.workplace }}</div>
        </mat-expansion-panel-header>
        <mat-card>
          <!-- header  -->
          <!-- <mat-card-header class="">
          <mat-card-title>Место работы: {{ e.workplace }}</mat-card-title>
          <mat-card-subtitle>Должность: {{ e.position }}</mat-card-subtitle>
        </mat-card-header> -->
        <!-- content  -->
        <mat-card-content>
          <mat-list>
            <mat-list-item>
              <mat-card-subtitle>Должность: {{ e.position }}</mat-card-subtitle>
            </mat-list-item>
            <!-- <mat-list-item>
              <div matLine>Табельный номер: {{ e.employee_id }}</div>
            </mat-list-item> -->
            <mat-list-item>
              <div matLine>Дата устройства: {{ e.from_date }}</div>
            </mat-list-item>
            <mat-list-item>
              <div matLine>Отдел: {{ e.department }}</div>
            </mat-list-item>
            <mat-list-item>
              <div matLine>Вид трудоустройства: {{ e.employment_type }}</div>
            </mat-list-item>
            <mat-list-item>
              <div matLine>GS ID: {{ e.structure_id }}</div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </mat-expansion-panel>
  }
</mat-accordion>
</div>

import { enableProdMode, ErrorHandler, importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LayoutModule } from '@angular/cdk/layout';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MarkdownModule } from 'ngx-markdown';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PortalModule } from '@angular/cdk/portal';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { NotificationService } from './app/services/notification.service';
// import { ErrorInterceptor } from './app/services/error.interceptor';
import { JwtInterceptor } from './app/services/jwt.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AuthService2 } from './app/services/auth.service';
import { RoutePartsService } from './app/services/route-parts.service';
import { RolesGuard } from './app/services/roles.guard';
import { AuthGuard } from './app/services/auth.guard';
// import { RouterModule } from '@angular/router';
import { UpdateService } from './app/services/update.service';
import { ApiModule, OpenAPI, OpenAPIConfig } from './app/modules/api';
import { ErrorHandlerService } from './app/services/error-hander.service';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
// import { BarController, Colors, Legend } from 'chart.js';

if (environment.production) {
  enableProdMode();
}

export let API_URL = ""

function get_base_url(): string {
  if (location.origin.includes("localhost:4200") || location.origin.includes("127.0.0.1:5009")) {
    API_URL = "http://127.0.0.1:5009";
  } else {
    API_URL = location.protocol + "//" + location.hostname; // + ":3000";
  }
  console.log("main - API_URL: ", API_URL);
  return API_URL;
}

function getAccessToken(): Promise<string>  {
  return new Promise((resolve) => {
    const stored = localStorage.getItem('access_token')
    console.log("OpenAPIConfig - stored access_token requested");
    resolve(stored ? stored : "");
  });
}

export const OPEN_API_CONFIG: OpenAPIConfig = {
  BASE: get_base_url(),
  VERSION: '0.1.0',
  WITH_CREDENTIALS: OpenAPI.WITH_CREDENTIALS,
  CREDENTIALS: OpenAPI.CREDENTIALS,
  TOKEN: getAccessToken,
  USERNAME: OpenAPI.USERNAME,
  PASSWORD: OpenAPI.PASSWORD,
  HEADERS: OpenAPI.HEADERS,
  ENCODE_PATH: OpenAPI.ENCODE_PATH,
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      ApiModule,
      AppRoutingModule,
      BrowserModule,
      FlexLayoutModule,
      FormsModule,
      MatAutocompleteModule,
      MatBadgeModule, MatBottomSheetModule, MatButtonModule,
      MatButtonToggleModule, MatCardModule, MatCheckboxModule,
      MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule,
      MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule,
      MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule,
      MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule,
      MatSelectModule, MatSidenavModule, MatSlideToggleModule, MatSliderModule, MatSnackBarModule,
      MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule,
      MatTreeModule, PortalModule, ScrollingModule, ReactiveFormsModule, PdfViewerModule,
      MarkdownModule.forRoot(),
      ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
      LayoutModule,
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
      LoadingBarModule
    ),
    AuthGuard,
    RolesGuard,
    RoutePartsService,
    AuthService2,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: OpenAPI, useValue: OPEN_API_CONFIG },
    // { provide: ErrorHandler },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    NotificationService,
    UpdateService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideExperimentalZonelessChangeDetection(),
    // provideCharts({ registerables: [BarController, Legend, Colors] }) // todo: uncomment when ng2-charts is updated
    provideCharts(withDefaultRegisterables())
  ]
}).catch((err) => console.error(err));

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService2 } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { IClaim } from '../../model';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Observable, map, tap } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ViewServiceCommiteeRequestComponent, ModalDialogData } from '../view-service-commitee-request/view-service-commitee-request.component';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-view-service-commitee',
    templateUrl: './view-service-commitee.component.html',
    styleUrls: ['./view-service-commitee.component.scss'],
    imports: [MatButtonModule, AsyncPipe, MatListModule, MatCardModule, MatIconModule, MatDialogModule, RouterLink]
})
export class ViewServiceCommiteeComponent {
  // url: SafeResourceUrl;
  // claims: IClaim[] = [];
  claims_to_lk$: Observable<IClaim[]>;
  // modal = false;
  // count_claims = 0;
  count_claims_this_year = 0;

  constructor(private sanitize: DomSanitizer, public auth: AuthService2, private apiService: ApiService, private dialog: MatDialog) {

    this.claims_to_lk$ = this.apiService.getClaimService().pipe(
      // map((claims: IClaim[]) => claims.filter(i => i.to_lk != 'nottolk')),
      tap((claims: IClaim[]) => {
        // this.count_claims = claims.length;
        this.count_claims_this_year = 0;
        let i: IClaim;
        for (i of claims) {
          if (i.this_year) {
            this.count_claims_this_year += 1;
          }
        }
      })
    );
  }

//   openDialog(): void {
//     // entry.1776677854 - email
//     // entry.1476625615 - name
//     // entry.490676407 - phone
//     const url =
//       'https://docs.google.com/forms/d/1KQ7ovhJusM7FQuJ81lFtQ1OpFLR9FvUeFZp2yXUq-_c/viewform?' +
//       `entry.1776677854=${this.auth.profile?.personaldata.email}` +
//       '&' +
//       `entry.1476625615=${this.auth.profile?.personaldata.individual_name}` +
//       '&' +
//       `entry.490676407=${this.auth.profile?.login}`;

//     const data: ModalDialogData = {url: this.sanitize.bypassSecurityTrustResourceUrl(url)};

//     const dialogRef = this.dialog.open(ModalFormComponent, {
//       width: '90%',
//       height: '90%',
//       maxWidth: '90vw',
//       data: data,
//     });

//     dialogRef.afterClosed().subscribe(() => {
//       console.log('The dialog was closed');
//     });
//   }

}

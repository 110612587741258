import { Component } from '@angular/core';
import { ViewDocComponent } from '../view-doc/view-doc.component';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AuthService2 } from 'src/app/services/auth.service';

export interface ModalDialogData {
  url: SafeResourceUrl;
}

@Component({
    selector: 'app-modal-form',
    templateUrl: './view-service-commitee-request.component.html',
    styleUrls: ['./view-service-commitee-request.component.scss'],
    imports: [ViewDocComponent]
})
export class ViewServiceCommiteeRequestComponent {
  url: string;
  constructor(public auth: AuthService2) {
    // Форма: https://docs.google.com/forms/d/1KQ7ovhJusM7FQuJ81lFtQ1OpFLR9FvUeFZp2yXUq-_c
    this.url =
      'https://docs.google.com/forms/d/e/1FAIpQLSf9PXAYJQG24YljvPrcALY22M6iX43ncH3F311uVYJZM6swhQ/viewform?embedded=true?' +
      `entry.1776677854=${this.auth.profile?.personaldata.email}` +
      '&' +
      `entry.1476625615=${this.auth.profile?.personaldata.individual_name}` +
      '&' +
      `entry.490676407=${this.auth.profile?.login}`;
    }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
// import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { IHotelPersonShiftInfo } from 'src/app/model';
import { EmployeeService, HotelShifts, PersonShifts } from 'src/app/modules/api';

export interface HotelShiftsEx extends HotelShifts {
  data_source?: MatTableDataSource<PersonShifts>;
}  

@Component({
    selector: 'app-view-shifts',
    imports: [MatTableModule, MatSelectModule, MatFormFieldModule, FormsModule, MatIconModule, DatePipe],
    templateUrl: './view-shifts.component.html',
    styleUrl: './view-shifts.component.scss'
})
export class ViewShiftsComponent implements OnInit {
  // private _liveAnnouncer = inject(LiveAnnouncer);

  columnsIDs: string[] = ['name', 'service'];
  displayedDateCols: Date[] = [];
  hotels: HotelShiftsEx[] | null = null;

  selectedService = "";
  services = [
    {value: ""},
    // {value: ShiftsService.FN_B},
    // {value: ShiftsService.HSKP},
    {value: 'СПиР'},
    {value: 'HSKP'},
    {value: 'FnB'},
    {value: 'СЭ'},
  ]

  // @ViewChild(MatSort) sort: MatSort;

  constructor(private employeeService: EmployeeService) {
  }

  ngOnInit(): void {
    const now = new Date();
    // now.setHours(0, 0, 0, 0)
    // const todayStr = now.toISOString();
    const todayStr = now.toLocaleDateString('ru-RU'); 
    this.employeeService.getHotelShiftsApiEmployeeHotelShiftsGet(todayStr, 14).subscribe(
      (shifts: HotelShifts[]) => {
        console.log('Shifts: ', shifts);
        this.hotels = shifts;
        // const now = dayjs();
        for (const shift of this.hotels) {
          shift.data_source = new MatTableDataSource(shift.people);        
        }
        this.columnsIDs.length = 2;
        this.displayedDateCols.length = 0;
        if (this.hotels.length) {
          let id = 0;
          for (const d of this.hotels[0].dates) {
            this.columnsIDs.push("dc_" + (id++).toString());
            this.displayedDateCols.push(new Date(d));
          }
        }
        // console.log(this.columnsIDs)
        // console.log(this.displayedDateCols)    
      }
    );
  }

  get_shift_text(person: IHotelPersonShiftInfo, index: number): string {
    const day = person.day[index];
    const night = person.night[index];
    if (day === 0 && night === 0) {
      return "";
    } else {
      return `д:${day}\nн:${night}`; 
      // return `${day+night}`;
    }
  }

  get_icon(service: string): string {
    if (service === 'СПиР') {
      return "person";
    } else if (service === 'HSKP') {
      return "cleaning_services";
    } else if (service === 'FnB') {
      return 'restaurant';
    } else if (service === 'СЭ') {
      return 'engineering';
    } else {
      return 'question_mark';
    }
  }

  get_day_class(hotel: HotelShifts, d: Date): string {
    const aaa = new Date(d);
    aaa.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // console.log('Date is ', aaa, "-", hotel.dates[day], "- today ",  today, "aa ", aaa.valueOf(), " ads ", today.valueOf());
    if (aaa.valueOf() == today.valueOf())
      return "today";
    const weekday = aaa.getDay();
    return weekday === 0 || weekday === 6 ? "weekend" : "weekday"; 
  }

  
  applyFilter() {
    const filterValue = this.selectedService;
    console.log('Filter: ', filterValue);
    if (this.hotels)
      for (const hotel of this.hotels) 
        if (hotel.data_source) {
          hotel.data_source.filterPredicate = (data: PersonShifts, filterString: string): boolean => {
            return data.service == filterString;
          }
          hotel.data_source.filter = filterValue;
        }
  }

}

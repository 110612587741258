import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ViewDocComponent } from '../view-doc/view-doc.component';

const html: string = "<div class='var-declare'>Премия_GRI</div><div class='desc'>= ((<span class='var-value'>0.88</span><span class='var-name'>[GRI]</span> - 80.0%) * 1500.0)</div><div class='desc'>= <span class='var-result'>120.0</span></div>"  +
"<div class='var-declare'>Премия_личные_продажи</div><div class='desc'>= (<span class='var-value'>910.0</span><span class='var-name'>[Сумма_личных_продаж]</span> * 15.0%)</div><div class='desc'>= <span class='var-result'>136.5</span></div>"  +
"<div class='var-declare'>Упоминания_в_Отзывах</div><div class='desc'>= (<span class='var-value'>3</span><span class='var-name'>[Количество_упомнинаний_в_отзывах]</span> * 500.0)</div><div class='desc'>= <span class='var-result'>1500.0</span></div>" +
"<div class='var-declare'>Премия</div><div class='desc'>= ((<span class='var-value'>120.0</span><span class='var-name'>[Премия_GRI]</span> + <span class='var-value'>136.5</span><span class='var-name'>[Премия_личные_продажи]</span>) + <span class='var-value'>500.0</span><span class='var-name'>[Премия_за_упоминания_в_отзывах]</span>)</div><div class='desc'>= <span class='var-result'>756.5</span></div>";


@Component({
  selector: 'app-view-bonus',
  imports: [],
  templateUrl: './view-bonus.component.html',
  styleUrl: './view-bonus.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ViewBonusComponent implements OnInit  {
  html: SafeHtml = "";
  
  ngOnInit(): void {
    this.html = html;
  }

}

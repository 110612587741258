@if (hotels) {
    <div class="header-container">
        <p>График ближайших дежурств (часы)</p>
        <mat-form-field>
            <mat-label>Выбор службы</mat-label>
            <mat-select [(ngModel)]="selectedService" name="service" (selectionChange)="applyFilter()">
                @for (option of services; track option) {
                    <mat-option [value]="option.value" ngDefaultControl>
                      {{ option.value }}
                    </mat-option>
                  }
            </mat-select>
        </mat-form-field>
    </div>

    <div class="table-container">
        @for (hotel of hotels; track hotel) {
        <h3 class="hotel-name">{{ hotel.hotel }}</h3>
        <div>
            <table mat-table [dataSource]="hotel.data_source" class="mat-elevation-z8">
                <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell *matHeaderCellDef>
                        ФИО
                    </th>
                    <td mat-cell *matCellDef="let element" class="name-font row-height"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="service" sticky>
                    <th mat-header-cell *matHeaderCellDef class="right-border-bold">
                        <!-- <mat-icon>home</mat-icon> -->
                    </th>
                    <td mat-cell *matCellDef="let element" class="right-border-bold row-height">
                        <mat-icon>{{ get_icon(element.service) }}</mat-icon>
                    </td>
                </ng-container>

                @for (disCol of displayedDateCols; track disCol; let colIndex = $index) {
                    <ng-container matColumnDef="{{columnsIDs[colIndex + 2]}}">
                        <th mat-header-cell *matHeaderCellDef class="shift-column right-border">
                            {{disCol | date: 'dd' }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="shift-column right-border row-height cell-font"
                            [class]="get_day_class(hotel, disCol)"  
                        > 
                            {{ get_shift_text(element, colIndex) }}
                            </td>
                    </ng-container>
                }

                <tr mat-header-row *matHeaderRowDef="columnsIDs; sticky: true" class="gray row-height"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsIDs" class="row-height"></tr>
            </table>
        </div>
        }
    </div> 

} @else {
    <div class="view-loader">
        <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
        </div>
    </div>
}
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-view-error',
    imports: [RouterLink, MatButtonModule],
    templateUrl: './view-error.component.html',
    styleUrl: './view-error.component.scss'
})
export class ViewErrorComponent {

}

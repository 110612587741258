{
  "name": "employee-portal",
  "version": "0.16.35",
  "scripts": {
    "start": "ng serve --hmr",
    "analyze": "source-map-explorer dist/*.*",
    "analyze:prod": "source-map-explorer dist_prod/*.*",
    "version-patch": "npm --no-git-tag-version version patch",
    "build": "ng build",
    "build:prod": "ng build --configuration production --output-hashing=none",
    "deploy": "bash scripts/deploy.sh",
    "deploy:prod": "bash scripts/deploy-prod.sh",
    "generate:api": "npx openapi --input http://localhost:5009/openapi.json --output ./src/app/modules/api --client angular --name ApiModule",
    "generate:api2": "npx @hey-api/openapi-ts -i http://localhost:5009/openapi.json -o ./src/app/modules/api -c @hey-api/client-fetch --name ApiModule ",
    "update": "npm run build && npm run deploy",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.4",
    "@angular/cdk": "^19.1.2",
    "@angular/common": "^19.1.4",
    "@angular/compiler": "^19.1.4",
    "@angular/core": "^19.1.4",
    "@angular/flex-layout": "15.0.0-beta.42",
    "@angular/forms": "^19.1.7",
    "@angular/material": "^19.1.5",
    "@angular/platform-browser": "^19.1.4",
    "@angular/platform-browser-dynamic": "^19.1.7",
    "@angular/router": "^19.1.7",
    "@angular/service-worker": "^19.1.7",
    "@ngx-loading-bar/core": "^7.0.0",
    "@ngx-loading-bar/http-client": "^7.0.0",
    "@ngx-loading-bar/router": "^7.0.0",
    "chart.js": "^4.4.8",
    "dayjs": "^1.11.13",
    "ng2-charts": "^8.0.0",
    "ng2-pdf-viewer": "^10.4.0",
    "ngx-markdown": "^19.1.0",
    "rxjs": "^7.8.2",
    "tslib": "^2.8.1"
  },
  "devDependencies": {
    "@angular/build": "^19.1.8",
    "@angular/cli": "^19.1.8",
    "@angular/compiler-cli": "^19.1.7",
    "@angular/language-service": "^19.1.7",
    "@eslint/js": "^9.21.0",
    "@hey-api/client-fetch": "^0.8.1",
    "@hey-api/openapi-ts": "^0.64.5",
    "@tanstack/angular-query-experimental": "^5.66.9",
    "@types/marked": "^4.3.2",
    "@types/node": "^22.13.5",
    "angular-eslint": "19.1.0",
    "eslint": "^9.20.0",
    "globals": "^16.0.0",
    "minio": "^7.1.3",
    "openapi-typescript-codegen": "^0.29.0",
    "source-map-explorer": "^2.5.3",
    "ts-node": "~10.9.1",
    "typescript": "^5.7.3",
    "typescript-eslint": "8.23.0"
  }
}
import { Route } from '@angular/router';
import { ViewAccountComponent } from './components/view-account.component/view-account.component';
import { ViewBirthdaysComponent } from './components/view-birthdays/view-birthdays.component';
import { ViewBountyDetailsComponent } from './components/view-bounty-details/view-bounty-details.component';
import { ViewFaqComponent } from './components/view-faq/view-faq.component';
import { ViewGraphicVacationComponent } from './components/view-graphic-vacation/view-graphic-vacation.component';
import { ViewGriComponent } from './components/view-gri/view-gri.component';
import { ViewLoginSimpleComponent } from './components/view-login-simple/view-login-simple.component';
import { ViewMagazineComponent } from './components/view-magazine/view-magazine.component';
import { ViewMotivationComponent } from './components/view-motivation/view-motivation.component';
import { ViewPersonalServicesComponent } from './components/view-personal-services/view-personal-services.component';
import { ViewResetPasswordComponent } from './components/view-reset-password/view-reset-password.component';
import { ViewSalaryComponent } from './components/view-salary/view-salary.component';
import { ViewServiceCommiteeRequestComponent } from './components/view-service-commitee-request/view-service-commitee-request.component';
import { ViewServiceCommiteeComponent } from './components/view-service-commitee/view-service-commitee.component';
import { ViewShiftsComponent } from './components/view-shifts/view-shifts.component';
import { ViewSpecialOffersComponent } from './components/view-special-offers/view-special-offers.component';
import { ViewStaticComponent } from './components/view-static/view-static.component';
import { ViewWikiComponent } from './components/view-wiki/view-wiki.component';
import { ViewWorkScheduleComponent } from './components/view-work-schedule/view-work-schedule.component';
import { RolesGuard } from './services/roles.guard';
import { ViewErrorComponent } from './components/view-error/view-error.component';
import { ViewBonusComponent } from './components/view-bonus/view-bonus.component';

const ROLES_USERS = ['user', 'admin'];
const ROLES_ADMIN = ['admin'];
const ROLES_HOTELS = ['admin', 'hotel'];
const ROLES_ALL: string[] = [];

export interface RouteData  {
  name: string;
  icon: string;  // empty icon - no menu
  roles: string[];
  root?: string;
  asset?: string;  // optional path to asset file (e.g., for static pages)
}

export interface LocalRoute extends Route {
  data: RouteData;
}


export const routes: LocalRoute[] = [
  // TODO: Make nested routes.
  { path: 'login', 
    component: ViewLoginSimpleComponent, 
    data: { name: 'Вход', icon: '', roles: ROLES_ALL }
  },
  {
    path: 'reset_password',
    component: ViewResetPasswordComponent,
    data: { name: 'Сброс пароля', icon: '', roles: ROLES_ALL },
  },
  {
    path: 'account',
    component: ViewAccountComponent,
    canActivate: [RolesGuard],
    data: { name: 'Профиль', icon:"", roles: ROLES_ALL },
  },
  {
    path: 'magazine',
    component: ViewMagazineComponent,
    canActivate: [RolesGuard],
    data: { name: 'Новости', icon: 'receipt', roles: ROLES_ALL },
  },
  {
    path: 'wiki',
    component: ViewWikiComponent,
    canActivate: [RolesGuard],
    data: { name: 'Стандарты', icon: "list", roles: ROLES_ALL },
  },
  {
    path: 'wiki/:_id',
    component: ViewStaticComponent,
    canActivate: [RolesGuard],
    data: { name: 'Стандарт', icon: "", roles: ROLES_ALL },
  },
  {
    path: 'gri',
    component: ViewGriComponent,
    canActivate: [RolesGuard],
    data: { name: 'GRI', icon: "assessment", roles: ROLES_ALL },
  },
  {
    path: 'motivation',
    component: ViewMotivationComponent,
    canActivate: [RolesGuard],
    data: { name: 'Схема мотивации', icon: "emoji_emotions", roles: ROLES_USERS },
  },
  {
    path: 'salary',
    component: ViewSalaryComponent,
    canActivate: [RolesGuard],
    data: { name: 'Доходы', icon: "monetization_on", roles: ROLES_USERS },
  },
  // TODO: Change to params for consistency?
  {
    path: 'bounty/:period/:company/:position',
    component: ViewBountyDetailsComponent,
    canActivate: [RolesGuard],
    data: { name: 'Детализация премии', icon: "", root: 'salary', roles: ROLES_USERS },
  },
  {
    path: 'work-schedule',
    component: ViewWorkScheduleComponent,
    canActivate: [RolesGuard],
    data: { name: 'График работы', icon: "calendar_today", roles: ROLES_USERS },
  },
  {
    path: 'shifts',
    component: ViewShiftsComponent,
    canActivate: [RolesGuard],
    data: { name: 'Cмены по отелю', icon: "calendar_today", roles: ROLES_HOTELS },
  },
  {
    path: 'personal-services',
    component: ViewPersonalServicesComponent,
    canActivate: [RolesGuard],
    data: { name: 'Личный раздел', icon: "assignment", roles: ROLES_USERS },
  },
  {
    path: 'graphic-vacation',
    component: ViewGraphicVacationComponent,
    canActivate: [RolesGuard],
    data: { name: 'График отпусков', icon: "local_airport", roles: ROLES_USERS },
  },
  {
    path: 'birthday',
    component: ViewBirthdaysComponent,
    canActivate: [RolesGuard],
    data: { name: 'Дни рождения', icon: "cake", roles: ROLES_ALL },
  },
  {
    path: 'special-offers',
    component: ViewSpecialOffersComponent,
    canActivate: [RolesGuard],
    data: { name: 'Спецпредложения', icon: "local_offer", roles: ROLES_ALL },
  },
  {
    path: 'service-commitee',
    component: ViewServiceCommiteeComponent,
    canActivate: [RolesGuard],
    data: { name: 'Сервисный комитет', icon: "feedback", roles: ROLES_USERS },
  },
  {
    path: 'bonus-test',
    component: ViewBonusComponent,
    canActivate: [RolesGuard],
    data: { name: 'Тест Мотивации', icon: "feedback", roles: ROLES_ADMIN },
  },
  {
    path: 'service-commitee/request',
    component: ViewServiceCommiteeRequestComponent,
    canActivate: [RolesGuard],
    data: { name: 'Новая заявка', icon: "", roles: ROLES_USERS },
  },
  {
    path: 'faq',
    component: ViewFaqComponent,
    canActivate: [RolesGuard],
    data: { asset: './assets/faq.md', icon: "info", name: 'FAQ', roles: ROLES_ALL },
  },
  {
    path: 'error',
    component: ViewErrorComponent,
    data: {name: 'Ошибка', icon: "", roles: ROLES_ALL },
  },
  { 
    path: '**', 
    redirectTo: '/magazine', 
    data: { name: 'Новости', icon: '', roles: ROLES_ALL } 
  },
  // {
  //   path: 'set-position',
  //   // component: ViewUserManagerComponent,
  //   loadComponent: () => import('./components/view-user-manager/view-user-manager.component').then(m => m.ViewUserManagerComponent),
  //   canActivate: [RolesGuard],
  //   data: { name: 'Назначить должность', roles: ['admin'] },
  // },
];

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ITheme {
  publicName: string;
  className: string;
}

const CUR_THEME_LS_SLOT = 'current_theme';

@Injectable({providedIn: 'root'})
export class ThemeService {
  public availableThemesList: ITheme[] = [
    {
      publicName: 'light',
      className: 'light-theme',
    },
    {
      publicName: 'dark',
      className: 'dark-theme',
    },
  ];
  public activatedTheme: ITheme = this.availableThemesList[0];
  private themeNotification = new BehaviorSubject<string>(this.activatedTheme.className);

  constructor(@Inject(DOCUMENT) private document: Document) {
    const val = localStorage.getItem(CUR_THEME_LS_SLOT);
    if (val) {
      this.changeTheme(val);
    }
  }

  getThemeClassName(): Observable<string> {
    return this.themeNotification;
  }

  changeTheme(themeName: string) {
    for (const theme of this.availableThemesList) {
      if (themeName === theme.publicName) {
        this.activatedTheme = theme;
        this.themeNotification.next(this.activatedTheme.className)
        localStorage.setItem(CUR_THEME_LS_SLOT, themeName);
        return
      }
    }
  }
}

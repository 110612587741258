<!-- @if (worksheets$ | async; as worksheets) 
{ -->
<div class="cal-container">
  <mat-calendar #calendar [dateClass]="dateClass"></mat-calendar>
  <hr/>
  <div class="buttons">
    <button mat-mini-fab color="primary" (click)="prev_period()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <p class="buttons-caption"> {{ activeDate | date: 'yyyy-MM' }} </p>
    <button mat-mini-fab color="primary" (click)="next_period()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>

  @if (worksheets && !worksheets.length) {
    <mat-card>
      <mat-card-header>
        <mat-card-title>Нет данных</mat-card-title>
        <mat-card-subtitle>Информация по графикам сменной работы для сотрудника в заданном периоде отсутсвует</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
    }
  
</div>
<!-- } @else {
<div class="view-loader">
  <div class="spinner">
    <div class="double-bounce1 mat-bg-accent"></div>
    <div class="double-bounce2 mat-bg-primary"></div>
  </div>
</div>
} -->
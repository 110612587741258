import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
//import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { egretAnimations } from 'src/app/animations/egret-animations';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';

export interface ShiftInfo {
  company: string,
  position: string,
  fact_day_hours: number,
  fact_night_hours: number,
  plan_day_hours: number,
  plan_night_hours: number,
};

export interface MatDialogData {
  date: Date;
  shifts: ShiftInfo[];
}

@Component({
    selector: 'app-view-work-schedule-popup',
    templateUrl: './view-work-schedule-popup.component.html',
    styleUrls: ['./view-work-schedule-popup.component.scss'],
    animations: egretAnimations,
    imports: [
        MatDialogModule,
        MatCardModule,
        MatProgressBarModule,
        MatDividerModule,
        FlexModule,
        MatButtonModule,
        DatePipe
    ]
})
export class ViewWorkSchedulePopupComponent {
  // formGroup: FormGroup;
  shifts: ShiftInfo[];
  date: Date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData,
    public dialogRef: MatDialogRef<ViewWorkSchedulePopupComponent>
  ) {
    this.date = data.date;
    this.shifts = data.shifts as ShiftInfo[];
    console.log('WorkSchedulePopup', this.shifts);
  }

  submit(): void {
    this.dialogRef.close();
  }
}

import { Component } from '@angular/core';
import { IWikiPage } from '../../model';
import { ApiService } from '../../services/api.service';
import { ViewDocComponent } from '../view-doc/view-doc.component';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-view-special-offers',
    templateUrl: './view-special-offers.component.html',
    styleUrls: ['./view-special-offers.component.scss'],
    imports: [ViewDocComponent, AsyncPipe]
})
export class ViewSpecialOffersComponent {
  public page$: Observable<IWikiPage>;

  constructor(private apiService: ApiService) {
    this.page$ = this.apiService.getWikiPage('754cd974-0aeb-59a0-a468-bd1a3f726200')
  }
}

import { Location } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { IWorkplacePosition } from 'src/app/model';
import { ViewDocComponent } from '../view-doc/view-doc.component';

@Component({
    selector: 'app-view-bounty-details',
    templateUrl: './view-bounty-details.component.html',
    styleUrls: ['./view-bounty-details.component.scss'],
    imports: [ViewDocComponent]
})
export class ViewBountyDetailsComponent implements OnChanges {
  html: SafeHtml = "";

  @Input() company = "";
  @Input() position = "";
  @Input() period = "";

  constructor(private apiService: ApiService, private route: ActivatedRoute, private location: Location) {
    this.route.params.subscribe((p) => {
      const params = p as IWorkplacePosition;
      console.log('ViewBountyPositionComponent', params);
      this.period = params.period;
      this.company = decodeURI(params.company);
      this.position = decodeURI(params.position);
      this.reloadNotebook();
    });
  }

  ngOnChanges(): void {
    // changes.prop contains the old and the new value...
    // todo: react only on specific change
    this.reloadNotebook();
  }

  reloadNotebook(): void {
    this.apiService.getEmployeeBountyNotebookForPeriod(this.period, this.position, this.company).subscribe((html) => {
      this.html = html;
    });
  }

  onBack(): void {
    this.location.back();
  }
}

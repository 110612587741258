:host {
  display: block;
  height: 100%;
  overflow: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
#tab-group .mat-tab-body-wrapper {
  flex-grow: 1;
}

.selector {
  display: block !important;
  max-width: 300px;
}

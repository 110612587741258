.container {
    padding: 6px;
}

.var-name {
    font-weight: 300;
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
    background-color: #e9f5ff;
    border-radius: 3px;
    font-style: italic;
    color: #0056b3;
}

.var-value {
    color: blue
}

.var-declare, .var-result {
    color: blue;
    font-weight: 600;
}

.desc {
    margin-left: 30px;
}

span, p {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
}